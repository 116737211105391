import { defineMessages } from 'react-intl'

const br = defineMessages({
  saopaulo: { id: 'Areas.SaoPaulo', defaultMessage: 'Sao Paulo' },
  riodeajneiro: { id: 'Areas.RioDeJaneiro', defaultMessage: 'Rio de Janeiro' },
})

const de = defineMessages({
  augsburg: { id: 'Areas.Augsburg', defaultMessage: 'Augsburg' },
  badenwurttemberg: {
    id: 'Areas.BadenWurttemberg',
    defaultMessage: 'Baden-Wurttemberg',
  },
  bavaria: { id: 'Areas.Bavaria', defaultMessage: 'Bavaria' },
  berlin: { id: 'Areas.Berlin', defaultMessage: 'Berlin' },
  bielefeld: { id: 'Areas.Bielefeld', defaultMessage: 'Bielefeld' },
  bochum: { id: 'Areas.Bochum', defaultMessage: 'Bochum' },
  brandenburg: { id: 'Areas.Brandenburg', defaultMessage: 'Brandenburg' },
  bremen: { id: 'Areas.Bremen', defaultMessage: 'Bremen' },
  chemnitz: { id: 'Areas.Chemnitz', defaultMessage: 'Chemnitz' },
  cologne: { id: 'Areas.Cologne', defaultMessage: 'Cologne' },
  darmstadt: { id: 'Areas.Darmstadt', defaultMessage: 'Darmstadt' },
  dortmundessen: {
    id: 'Areas.DortmundEssen',
    defaultMessage: 'Dortmund / Essen',
  },
  dresden: { id: 'Areas.Dresden', defaultMessage: 'Dresden' },
  dusseldorf: { id: 'Areas.Dusseldorf', defaultMessage: 'Dusseldorf' },
  erfurt: { id: 'Areas.Erfurt', defaultMessage: 'Erfurt' },
  frankfurt: { id: 'Areas.Frankfurt', defaultMessage: 'Frankfurt' },
  freiburg: { id: 'Areas.Freiburg', defaultMessage: 'Freiburg' },
  hamburg: { id: 'Areas.Hamburg', defaultMessage: 'Hamburg' },
  hannover: { id: 'Areas.Hannover', defaultMessage: 'Hannover' },
  heidelberg: { id: 'Areas.Heidelberg', defaultMessage: 'Heidelberg' },
  hesse: { id: 'Areas.Hesse', defaultMessage: 'Hesse' },
  jena: { id: 'Areas.Jena', defaultMessage: 'Jena' },
  karlsruhe: { id: 'Areas.Karlsruhe', defaultMessage: 'Karlsruhe' },
  kassel: { id: 'Areas.Kassel', defaultMessage: 'Kassel' },
  leipzig: { id: 'Areas.Leipzig', defaultMessage: 'Leipzig' },
  lowersaxony: { id: 'Areas.LowerSaxony', defaultMessage: 'Lower Saxony' },
  mannheim: { id: 'Areas.Mannheim', defaultMessage: 'Mannheim' },
  mecklenburgvorpommern: {
    id: 'Areas.MecklenburgVorpommern',
    defaultMessage: 'Mecklenburg-Vorpommern',
  },
  munich: { id: 'Areas.Munich', defaultMessage: 'Munich' },
  munster: { id: 'Areas.Munster', defaultMessage: 'Munster' },
  northrhinewestphalia: {
    id: 'Areas.NorthRhineWestphalia',
    defaultMessage: 'North Rhine-Westphalia',
  },
  nurnberg: { id: 'Areas.Nuremburg', defaultMessage: 'Nuremburg' },
  regensburg: { id: 'Areas.Regensburg', defaultMessage: 'Regensburg' },
  rhinelandpalatinate: {
    id: 'Areas.RhinelandPalatinate',
    defaultMessage: 'Rhineland-Palatinate',
  },
  saarland: { id: 'Areas.Saarland', defaultMessage: 'Saarland' },
  saxony: { id: 'Areas.Saxony', defaultMessage: 'Saxony' },
  saxonyanhalt: { id: 'Areas.SaxonyAnhalt', defaultMessage: 'Saxony-Anhalt' },
  schleswigholstein: {
    id: 'Areas.SchleswigHolstein',
    defaultMessage: 'Schleswig-Holstein',
  },
  stuttgart: { id: 'Areas.Stuttgart', defaultMessage: 'Stuttgart' },
  thuringia: { id: 'Areas.Thuringia', defaultMessage: 'Thuringia' },
})

const es = defineMessages({
  barcelona: { id: 'Areas.Barcelona', defaultMessage: 'Barcelona' },
  canaryislands: {
    id: 'Areas.CanaryIslands',
    defaultMessage: 'Canary Islands',
  },
  east: { id: 'Areas.East', defaultMessage: 'East' },
  ibiza: { id: 'Areas.Ibiza', defaultMessage: 'Ibiza' },
  madrid: { id: 'Areas.Madrid', defaultMessage: 'Madrid' },
  north: { id: 'Areas.North', defaultMessage: 'North' },
  south: { id: 'Areas.South', defaultMessage: 'South' },
})

const fr = defineMessages({
  central: { id: 'Areas.Central', defaultMessage: 'Central' },
  east: { id: 'Areas.East', defaultMessage: 'East' },
  frenchriviera: {
    id: 'Areas.FrenchRiviera',
    defaultMessage: 'French Riviera',
  },
  frenchwestindies: {
    id: 'Areas.FrenchWestIndies',
    defaultMessage: 'French West Indies',
  },
  lyon: { id: 'Areas.Lyon', defaultMessage: 'Lyon' },
  marseille: { id: 'Areas.Marseille', defaultMessage: 'Marseille' },
  montpellier: { id: 'Areas.Montpellier', defaultMessage: 'Montpellier' },
  nantes: { id: 'Areas.Nantes', defaultMessage: 'Nantes' },
  north: { id: 'Areas.North', defaultMessage: 'North' },
  paris: { id: 'Areas.Paris', defaultMessage: 'Paris' },
  southeast: { id: 'Areas.SouthEast', defaultMessage: 'South East' },
  southwest: { id: 'Areas.SouthWest', defaultMessage: 'South West' },
  west: { id: 'Areas.West', defaultMessage: 'West' },
})

const it = defineMessages({
  bologna: { id: 'Areas.Bologna', defaultMessage: 'Bologna' },
  central: { id: 'Areas.Central', defaultMessage: 'Central' },
  florence: { id: 'Areas.Florence', defaultMessage: 'Florence' },
  milan: { id: 'Areas.Milan', defaultMessage: 'Milan' },
  naples: { id: 'Areas.Naples', defaultMessage: 'Naples' },
  north: { id: 'Areas.North', defaultMessage: 'North' },
  rome: { id: 'Areas.Rome', defaultMessage: 'Rome' },
  sicily: { id: 'Areas.Sicily', defaultMessage: 'Sicily' },
  south: { id: 'Areas.South', defaultMessage: 'South' },
  turin: { id: 'Areas.Turin', defaultMessage: 'Turin' },
  venice: { id: 'Areas.Venice', defaultMessage: 'Venice' },
})

const jp = defineMessages({
  chubu: { id: 'Areas.Chubu', defaultMessage: 'Chubu' },
  chugoku: { id: 'Areas.Chugoku', defaultMessage: 'Chugoku' },
  hokkaido: { id: 'Areas.Hokkaido', defaultMessage: 'Hokkaido' },
  kansai: { id: 'Areas.Kansai', defaultMessage: 'Kansai' },
  kanto: { id: 'Areas.Kanto', defaultMessage: 'Kanto' },
  kyushu: { id: 'Areas.Kyushu', defaultMessage: 'Kyushu' },
  okinawa: { id: 'Areas.Okinawa', defaultMessage: 'Okinawa' },
  shikoku: { id: 'Areas.Shikoku', defaultMessage: 'Shikoku' },
  tohoku: { id: 'Areas.Tohoku', defaultMessage: 'Tohoku' },
  tokyo: { id: 'Areas.Tokyo', defaultMessage: 'Tokyo' },
})

const mx = defineMessages({
  guadalajara: { id: 'Areas.Guadalajara', defaultMessage: 'Guadalajara' },
  mexicocity: { id: 'Areas.MexicoCity', defaultMessage: 'Mexico City' },
  monterrey: { id: 'Areas.Monterrey', defaultMessage: 'Monterrey' },
  north: { id: 'Areas.North', defaultMessage: 'North' },
  puertoescondido: {
    id: 'Areas.PuertoEscondido',
    defaultMessage: 'Puerto Escondido',
  },
  south: { id: 'Areas.South', defaultMessage: 'South' },
  tijuana: { id: 'Areas.Tijuana', defaultMessage: 'Tijuana' },
  tulum: { id: 'Areas.Tulum', defaultMessage: 'Tulum' },
})

const nl = defineMessages({
  amsterdam: { id: 'Areas.Amsterdam', defaultMessage: 'Amsterdam' },
  eindhoven: { id: 'Areas.Eindhoven', defaultMessage: 'Eindhoven' },
  otherregions: { id: 'Areas.OtherRegions', defaultMessage: 'Other regions' },
  rotterdam: { id: 'Areas.Rotterdam', defaultMessage: 'Rotterdam' },
  thehague: { id: 'Areas.TheHague', defaultMessage: 'The Hague' },
  utrecht: { id: 'Areas.Utrecht', defaultMessage: 'Utrecht' },
})

const pt = defineMessages({
  algarve: { id: 'Areas.Algarve', defaultMessage: 'Algarve' },
  lisbon: { id: 'Areas.Lisbon', defaultMessage: 'Lisbon' },
  other: { id: 'Areas.OtherRegions', defaultMessage: 'Other regions' },
  porto: { id: 'Areas.Porto', defaultMessage: 'Porto' },
})

const messages = {
  br,
  de,
  es,
  fr,
  it,
  jp,
  mx,
  nl,
  pt,
}

export default messages
