import { useAnimation } from 'framer-motion'
import { useRef } from 'react'
import animation from 'themes/animation'

const useHandleScrollMovement = (onComplete) => {
  const scrollRef = useRef()
  const controls = useAnimation()

  const handleScroll = async (childRef) => {
    const { left: parentX } = scrollRef.current.getBoundingClientRect()
    const { left: childX } = childRef.current.getBoundingClientRect()
    const moveX = childX - parentX

    await controls.start({
      type: animation.mobileNavItems.easing,
      x: 0,
    })

    onComplete(scrollRef, moveX)
  }

  return [controls, scrollRef, handleScroll]
}

export default useHandleScrollMovement
