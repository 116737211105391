import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Slash, Alignment, variants } from '@resident-advisor/design-system'
import Link, { hrefAsPropTypes } from 'components/generic/link'
import styled, { css } from 'styled-components'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'

const MobileNavItem = ({
  href,
  as,
  title,
  index,
  active,
  length,
  strikeThrough,
  handleItemFocus = () => ({}),
  onClick,
  variantSize,
}) => {
  const menuItemRef = useRef()
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)

  useEffect(() => {
    if (active && menuItemRef.current) {
      handleItemFocus(menuItemRef)
    }
  }, [active, handleItemFocus, menuItemRef])

  return (
    <Alignment
      alignItems="center"
      as="li"
      onFocus={() => handleItemFocus(menuItemRef)}
      ref={menuItemRef}
    >
      <MenuLink
        strikeThrough={strikeThrough}
        color={index === 0 ? 'primary' : 'secondary'}
        variant={variants.text.heading[variantSize]}
        href={href}
        forwardedAs={as}
        active={active}
        onClick={onClick}
        enableNewMainNav={enableNewMainNav}
      >
        {title}
      </MenuLink>
      {index < length - 1 && (
        <Slash
          variant={variants.text.heading[variantSize]}
          color="tertiary"
          pl={2}
        />
      )}
    </Alignment>
  )
}

const MenuLink = styled(Link)`
  white-space: nowrap;

  ${({ theme, active, enableNewMainNav }) =>
    !enableNewMainNav &&
    active &&
    css`
      color: ${theme.navigation.activeColor};
    `}

  &:hover {
    ${({ theme, enableNewMainNav }) =>
      !enableNewMainNav &&
      css`
        color: ${theme.navigation.activeColor};
      `};

    background-color: transparent;
  }
`

MobileNavItem.propTypes = {
  href: hrefAsPropTypes.isRequired,
  as: hrefAsPropTypes,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  variantSize: PropTypes.string.isRequired,
  handleItemFocus: PropTypes.func,
  strikeThrough: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
}

export default MobileNavItem
