import { useApolloClient } from '@apollo/client'
import dict from 'messages/dict'
import auth from 'messages/auth'
import logout from 'lib/logout'
import { useUserContext } from 'context/UserContext'
import { useIntl } from 'react-intl'
import testIds from 'enums/testIds'
import PropTypes from 'prop-types'
import { useBeaconContext } from 'components/generic/helpscout/BeaconContext'
import BEACON_ACTION from 'enums/beacon-action'
import {
  Alignment,
  Box,
  Icon,
  Text,
  CommentIcon,
  variants,
} from '@resident-advisor/design-system'

import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import Tracking from 'scripts/tracking/Tracking'
import { TRACKING_EVENT } from 'scripts/tracking'
import useNavVersion from 'hooks/useNavVersion'

const useUserNavMenuItems = ({ showUsername = true } = {}) => {
  const { user } = useUserContext()

  const apolloClient = useApolloClient()
  const { callBeacon } = useBeaconContext()
  const beaconLogout = () => callBeacon(BEACON_ACTION.logout)
  const intl = useIntl()

  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)
  const enableSavedEvents = useFeatureSwitch(featureSwitches.enableSavedEvents)

  const navVersion = useNavVersion()

  if (!user) {
    return []
  }

  const getInboxComponent = () => <InboxComponent user={user} />

  const handleLogout = () => {
    Tracking.trackMixpanel(TRACKING_EVENT.clickedLogout, {
      'Nav Version': navVersion,
    })

    logout(apolloClient, beaconLogout)
  }

  const items = [
    {
      linkProps: { href: '/my-tickets' },
      title: intl.formatMessage(dict.myTickets),
    },
    {
      linkProps: { href: '/user/following' },
      title: intl.formatMessage(dict.following),
    },
    {
      linkProps: {
        href: enableNewMainNav ? '/user/inbox' : '/inbox.aspx',
      },
      title: intl.formatMessage(dict.inbox),
      Component: getInboxComponent,
    },
    {
      linkProps: { href: '/user/settings/account' },
      title: intl.formatMessage(dict.accountSettings),
    },
    {
      linkProps: { href: '#' },
      title: intl.formatMessage(auth.logout),
      onClick: () => handleLogout(),
      'data-test-id': testIds.logout,
    },
  ]

  const savedEventsItem = {
    linkProps: { href: '/user/saved-events' },
    title: intl.formatMessage(dict.savedEvents),
  }

  if (enableSavedEvents) items.splice(1, 0, savedEventsItem)

  return showUsername ? [{ title: user?.username }, ...items] : items
}

const InboxComponent = ({ user }) => {
  if (!user?.newMessagesCount) {
    return null
  }

  return (
    <Box display="inline-block" ml={2} mb={{ m: 1, l: 2 }}>
      <Alignment alignItems="center">
        <Icon Component={CommentIcon} color="accent" />
        <Text variant={variants.text.tag} color="secondary">
          {user.newMessagesCount}
        </Text>
      </Alignment>
    </Box>
  )
}

InboxComponent.propTypes = {
  user: PropTypes.shape({
    newMessagesCount: PropTypes.number,
  }),
}

export default useUserNavMenuItems
