import useUserNavMenuItems from 'hooks/useUserNavMenuItems'
import AuthenticatedComponent from 'components/auth/authenticated-component'
import GlobalNavList from './GlobalNavList'

const UserNavMenu = () => {
  const items = useUserNavMenuItems()

  return (
    <AuthenticatedComponent onAuthenticationFailed={() => null}>
      <GlobalNavList items={items} />
    </AuthenticatedComponent>
  )
}

export default UserNavMenu
