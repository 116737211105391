import PropTypes from 'prop-types'
import { useUserContext } from 'context/UserContext'
import Redirect from 'components/generic/redirect'
import { queryStringSetRelative } from 'lib/queryStringSet'
import { useRouter } from 'next/router'

const RedirectToLogin = ({ reload }) => {
  const { asPath } = useRouter()
  const options = reload ? { reloadUrl: asPath } : { returnUrl: asPath }

  return (
    <Redirect
      to={queryStringSetRelative('/login', {
        notAuthenticated: true,
        ...options,
      })}
    />
  )
}
RedirectToLogin.propTypes = {
  reload: PropTypes.bool,
}
const AuthenticatedComponent = ({
  reloadUrlOnSuccess = false,
  children,
  onAuthenticationFailed = () => (
    <RedirectToLogin reload={reloadUrlOnSuccess} />
  ),
}) => {
  const { isAuthenticated, loading } = useUserContext()

  if (loading) {
    return null
  }

  if (!isAuthenticated) {
    return onAuthenticationFailed()
  }

  return <>{children}</>
}

AuthenticatedComponent.propTypes = {
  reloadUrlOnSuccess: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onAuthenticationFailed: PropTypes.func,
}

export default AuthenticatedComponent
