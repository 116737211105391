import useBodyWidth from 'hooks/useBodyWidth'
import { isMobile as checkIsMobile } from 'lib/checkScreenWidth'

const useIsMobile = () => {
  const width = useBodyWidth()
  const isMobile = checkIsMobile(width)

  return isMobile
}

export default useIsMobile
