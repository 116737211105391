import { useState, useEffect } from 'react'

// Based on this:
// https://gist.github.com/reecelucas/cd110ece696cca8468db895281fa28cb

const SCROLL_UP = 'up'
const SCROLL_DOWN = 'down'

const useScrollDirection = ({ threshold = 0 } = {}) => {
  const [scrollDirection, setScrollDirection] = useState()

  useEffect(() => {
    let lastScrollY = window.pageYOffset

    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }

      setScrollDirection(scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP)
      lastScrollY = scrollY > 0 ? scrollY : 0

      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        ticking = true
        window.requestAnimationFrame(updateScrollDir)
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [threshold])

  return { scrollDirection, setScrollDirection }
}

export default useScrollDirection
