import { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {
  Slide,
  Alignment,
  Box,
  Icon,
  PersonIcon,
  variants,
} from '@resident-advisor/design-system'
import AuthenticatedPerson from 'components/shared/authenticated-person'
import messages from 'messages/auth'
import { useUserContext } from 'context/UserContext'
import { EaseInFromRightSide } from 'components/shared/animation'
import Link from 'components/generic/link'
import LoginModal from 'components/auth/modals/login/LoginModal'
import { AnimatePresence } from 'framer-motion'
import useUserNavMenuItems from 'hooks/useUserNavMenuItems'
import { FullWidthAbsolutePosition } from 'components/generic/full-width'
import { TRACKING_PROPERTY } from 'scripts/tracking'
import RAProHorizontal from 'public/static/ra-pro-logo-horizontal.svg'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import NavLinkItem, {
  NavLinkItemType,
} from 'components/navigation/global-nav/NavLinkItem'
import { actions, useMenuContext } from 'context/MenuContext'
import PLAYWRIGHT_TEST_IDS from 'enums/playwright-test-ids'
import UserNavMenuItem from './UserNavMenuItem'
import { Container } from '../MobileNavItems'

const UserNavMenuItems = () => {
  const userNavMenuItems = useUserNavMenuItems({ showUsername: false })
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)
  const [{ showMobileMenuUserSettings }] = useMenuContext()

  const { user } = useUserContext()
  const [showLoginModal, setShow] = useState(false)
  const toggleShow = () => {
    setShow(!showLoginModal)
  }

  return (
    <Box mb={enableNewMainNav ? 4 : 2} pb={enableNewMainNav ? 2 : 0}>
      {user ? (
        <>
          <UserAndProLink user={user} toggleShow={toggleShow} />
          <FullWidthAbsolutePosition sizes={['s']}>
            <EaseInFromRightSide>
              {enableNewMainNav ? (
                <Alignment
                  flexDirection="column"
                  pt={showMobileMenuUserSettings ? 2 : 0}
                  display={{
                    s: showMobileMenuUserSettings ? 'block' : 'none',
                  }}
                >
                  {userNavMenuItems.map((item, index) => (
                    <Alignment pl={2}>
                      <Container>
                        <NavLinkItem
                          navLinkItemType={NavLinkItemType.MobileSubSection}
                          key={index}
                          href={item.linkProps.href}
                          text={item.title}
                          {...item}
                        />
                      </Container>
                    </Alignment>
                  ))}
                </Alignment>
              ) : (
                <Alignment flexDirection="column">
                  <Slide pt={4} sCols={userNavMenuItems.length} gridGap={0}>
                    {userNavMenuItems.map((item, index) => (
                      <Slide.Item key={item.id || index} alignItems="center">
                        <UserNavMenuItem
                          key={index}
                          index={index}
                          position={index + 1}
                          {...item}
                        />
                      </Slide.Item>
                    ))}
                    <OverscrollBox />
                  </Slide>
                </Alignment>
              )}
            </EaseInFromRightSide>
          </FullWidthAbsolutePosition>
        </>
      ) : (
        <>
          <Box pt={3}>
            <EaseInFromRightSide>
              <UserAndProLink user={user} toggleShow={toggleShow} />
            </EaseInFromRightSide>
          </Box>
          <AnimatePresence>
            {showLoginModal && (
              <LoginModal
                toggleShow={toggleShow}
                source={TRACKING_PROPERTY.loginSource.myAccount}
              />
            )}
          </AnimatePresence>
        </>
      )}
    </Box>
  )
}

const UserAndProLink = ({ user, toggleShow }) => {
  const intl = useIntl()
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)
  const [, dispatch] = useMenuContext()

  return (
    <Alignment alignItems="center" justifyContent="space-between">
      {user ? (
        <Box
          role="button"
          data-pw-test-id={PLAYWRIGHT_TEST_IDS.mobileNavModalUser}
          onClick={() =>
            dispatch({ type: actions.TOGGLE_MOBILE_MENU_USER_SETTINGS })
          }
        >
          <AuthenticatedPerson
            textVariant={variants.text.smallBold}
            iconSize={20}
            hasChevron={enableNewMainNav}
          />
        </Box>
      ) : (
        <Alignment alignItems="center">
          {enableNewMainNav && (
            <Icon
              ml={0}
              mr={2}
              color="primary"
              height="30px"
              width="30px"
              Component={PersonIcon}
            />
          )}

          <UserNavMenuItem
            textVariant={enableNewMainNav ? variants.text.smallBold : null}
            onClick={() => toggleShow()}
            title={intl.formatMessage(
              enableNewMainNav ? messages.myAccount : messages.login
            )}
          />
        </Alignment>
      )}
      <Link
        href="/pro"
        fontWeight="bold"
        display="flex"
        variant={variants.text.bodyBold}
        pl={1}
      >
        <Icon
          ml={2}
          mr={2}
          height={14}
          width={60}
          Component={RAProHorizontal}
        />
      </Link>
    </Alignment>
  )
}

UserAndProLink.propTypes = {
  user: PropTypes.object,
  toggleShow: PropTypes.func.isRequired,
}

const OverscrollBox = () => <Box width="100%" mr={3} />

export default UserNavMenuItems
