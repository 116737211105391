import MobileNavContent from 'components/navigation/mobile-nav/MobileNavContent'
import { opacity, zIndex } from 'themes'
import { actions, useMenuContext } from 'context/MenuContext'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import SideModal from 'components/generic/side-modal'

const MobileNavMenu = () => {
  const [{ showMobileMenu, globalNavHeight }, dispatch] = useMenuContext()
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)

  if (!enableNewMainNav || !showMobileMenu) return null
  return (
    <SideModal
      topOffset={`${globalNavHeight}px`}
      toggle={() => dispatch({ type: actions.TOGGLE_MOBILE_MENU })}
      backgroundOpacity={opacity.mobileMenuBackground}
      zIndex={zIndex.cornerNavModal}
      renderHeader={() => null}
      px={0}
    >
      <MobileNavContent />
    </SideModal>
  )
}

export default MobileNavMenu
