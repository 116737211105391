import {
  Text,
  Alignment,
  variants,
  colors,
} from '@resident-advisor/design-system'
import styled from 'styled-components'
import { GlobalNavLink } from './GlobalNavBarNew'
import hoverColorStyles from './hoverColorStyles'

const NavLinkItem = ({
  key,
  href,
  text,
  navLinkItemType,
  onClick,
}: NavLinkItemProps) => {
  let cssStyles

  switch (navLinkItemType) {
    case NavLinkItemType.Dropdown:
      cssStyles = {
        variant: variants.text.small,
        color: 'tertiary',
        hoverColor: colors.accent,
        justifyContent: 'start',
        fontWeight: 'normal',
        pt: 0,
        pb: 1,
      }
      break
    case NavLinkItemType.MobileSection:
      cssStyles = {
        variant: variants.text.body,
        color: 'primary',
        hoverColor: 'primary',
        justifyContent: 'start',
        fontWeight: 'bold',
        pt: 1,
        pb: 1,
      }
      break
    case NavLinkItemType.MobileSubSection:
      cssStyles = {
        variant: variants.text.body,
        color: 'tertiary',
        hoverColor: 'tertiary',
        justifyContent: 'start',
        fontWeight: 'normal',
        pt: 1,
        pb: 1,
      }
      break
    default:
      cssStyles = {
        variant: variants.text.small,
        color: 'primary',
        hoverColor: colors.accent,
        justifyContent: 'center',
        fontWeight: 'bold',
        pt: 0,
        pb: 0,
      }
      break
  }

  return (
    <GlobalNavLink key={key} href={href} onClick={onClick}>
      <HoverAlignment
        color={cssStyles.color}
        hoverColor={cssStyles.hoverColor}
        alignItems="center"
        justifyContent={cssStyles.justifyContent}
        px={{ s: 2, m: 3 }}
      >
        <Text
          color="inherit"
          hoverColor="inherit"
          variant={cssStyles.variant}
          fontWeight={cssStyles.fontWeight}
          pt={cssStyles.pt}
          pb={cssStyles.pb}
          textAlign="center"
          css={{ whiteSpace: 'no-wrap' }}
        >
          {text}
        </Text>
      </HoverAlignment>
    </GlobalNavLink>
  )
}

const HoverAlignment = styled(Alignment)`
  ${hoverColorStyles}
`

type NavLinkItemProps = {
  key?: string
  href: string
  text: string
  navLinkItemType: NavLinkItemType
  onClick?: () => void
}

enum NavLinkItemType {
  Standard,
  Dropdown,
  MobileSection,
  MobileSubSection,
}

export default NavLinkItem
export { NavLinkItemType }
