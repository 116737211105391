import { sizes } from '@resident-advisor/design-system'
import canUseDom from './canUseDom'

/**
 * This function should be used extremely sparingly, and only when
 * the responsive design capabilities of `styled-system` can't be used.
 */
const isMobile = (width) => canUseDom() && width < sizes.m

const isMedium = (width) => canUseDom() && width < sizes.l && width >= sizes.m

export { isMobile, isMedium }
