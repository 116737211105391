import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css, ThemeProvider } from 'styled-components'
import { space } from 'styled-system'
import { Box, Layout } from '@resident-advisor/design-system'
import { useMenuContext, actions } from 'context/MenuContext'
import SearchModal from 'components/shared/search-modal'
import { dark } from 'themes'
import GlobalNavModal, {
  MainNavMenu,
} from 'components/navigation/global-nav/global-nav-modal'
import { AnimatePresence } from 'framer-motion'
import trackingIds from 'tracking/tracking-ids'
import PLAYWRIGHT_TEST_IDS from 'enums/playwright-test-ids'
import testIds from 'enums/testIds'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import StickyNav from './StickyNav'
import GlobalNavBarNew from './GlobalNavBarNew'
import GlobalNavBarOld from './GlobalNavBarOld'

const GlobalNav = ({ showMobileGlobalNav }) => {
  const [{ color, showMenu, hasBackgroundColor }, dispatch] = useMenuContext()

  const [showSearch, setShowSearch] = useState(false)
  const toggleShowSearch = () => setShowSearch(!showSearch)
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)

  const [section, setSelectedSection] = useState(null)
  const toggleMenuSection = useCallback(
    (menuSection) => {
      if (menuSection && menuSection !== section) {
        setSelectedSection(menuSection)

        if (!showMenu) {
          dispatch({
            type: actions.OPEN_MENU,
          })
        }
      } else {
        setSelectedSection(null)

        dispatch({
          type: actions.CLOSE_MENU,
        })
      }
    },
    [dispatch, section, showMenu]
  )

  return (
    <div
      data-tracking-id={trackingIds.nav}
      data-testid={testIds.globalNav}
      data-pw-test-id={PLAYWRIGHT_TEST_IDS.globalNav}
    >
      <StickyNav showMenu={showMenu}>
        {({ beyondElement }) => {
          const navColor =
            beyondElement || showMenu || enableNewMainNav ? 'primary' : color
          return (
            <ThemeProvider theme={dark}>
              <GlobalNavStyled
                color={navColor}
                hasBackgroundColor={
                  enableNewMainNav ||
                  hasBackgroundColor ||
                  beyondElement ||
                  showMenu
                }
              >
                <GlobalNavBarNew
                  toggleShowSearch={toggleShowSearch}
                  toggleMenuSection={toggleMenuSection}
                />
                <GlobalNavBarOld
                  navColor={navColor}
                  toggleShowSearch={toggleShowSearch}
                  toggleMenuSection={toggleMenuSection}
                  section={section}
                  beyondElement={beyondElement}
                  showMobileGlobalNav={showMobileGlobalNav}
                />
              </GlobalNavStyled>
              <Layout>
                <AnimatePresence>
                  {showSearch && <SearchModal toggle={toggleShowSearch} />}
                </AnimatePresence>
                <AnimatePresence>
                  {section && (
                    <GlobalNavModal
                      section={section}
                      toggle={() => toggleMenuSection(null)}
                    />
                  )}
                </AnimatePresence>
              </Layout>
            </ThemeProvider>
          )
        }}
      </StickyNav>
      {/* Render hidden menu for SEO */}
      {!showMenu && (
        <Box display="none">
          <MainNavMenu />
        </Box>
      )}
    </div>
  )
}

GlobalNav.propTypes = {
  showMobileGlobalNav: PropTypes.bool,
}

GlobalNav.defaultProps = {
  showMobileGlobalNav: false,
}

const GlobalNavStyled = styled(Layout)`
  ${space};

  ${({ hasBackgroundColor, theme }) =>
    hasBackgroundColor &&
    css`
      background-color: ${theme.navigation.backgroundColor};
    `}
`

export default GlobalNav
