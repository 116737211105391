import axios from 'axios'
import cookieNames from 'lib/cookieNames'
import { destroyCookie } from 'lib/cookieHelpers'
import { captureError } from 'lib/logger'
import Tracking from 'scripts/tracking'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const LOGOUT_ROUTE_V2 = `${publicRuntimeConfig.AUTH_SERVICE}/api/v2/logout`

const logout = async (apolloClient, beaconLogout) => {
  try {
    destroyCookie(cookieNames.successfulLoginDuringSession)

    Tracking.resetIdentity()
    beaconLogout()
    const logoutPromises = [
      axios
        .post(LEGACY_LOGOUT_URL, null, { withCredentials: true })
        .catch((e) => captureError(e)), // Logout from legacy site (deletes ASP.NET session)

      apolloClient.cache.reset().catch((e) => captureError(e)), // Force a reload of all the current queries now that the user is logged out
      axios
        .post(LOGOUT_ROUTE_V2, null, { withCredentials: true }) // clear cookies using set-cookie headers
        .catch((e) => captureError(e)),
    ]

    await Promise.allSettled(logoutPromises)
  } catch (error) {
    captureError(error)
  }
  window.localStorage.clear()
  window.location.reload()
}

const LEGACY_LOGOUT_URL = '/logout.aspx'

export default logout
