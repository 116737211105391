import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { Layout, Section } from '@resident-advisor/design-system'
import { dark, opacity } from 'themes'
import Modal from 'components/generic/modal'
import { FadeIn } from 'components/shared/animation'
import dynamicLoadWithSpinner from 'lib/dynamicLoadWithSpinner'

const DynamicSearchModalContent = dynamicLoadWithSpinner(() =>
  import('./SearchContent')
)

const SearchModal = ({ toggle }) => {
  return (
    <ThemeProvider theme={dark}>
      <Modal
        toggle={toggle}
        focus={false}
        backgroundOpacity={opacity.searchModalBackground}
        allowModalScrolling
      >
        <FadeIn>
          <Section>
            <Layout>
              <Modal.Header toggle={toggle} />
            </Layout>
          </Section>
          <DynamicSearchModalContent />
        </FadeIn>
      </Modal>
    </ThemeProvider>
  )
}

SearchModal.propTypes = {
  toggle: PropTypes.func.isRequired,
}

export default SearchModal
