import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { Layout } from '@resident-advisor/design-system'
import Modal from 'components/generic/modal'
import useBodyWidth from 'hooks/useBodyWidth'
import { isMobile as checkIsMobile } from 'lib/checkScreenWidth'
import sections from 'components/navigation/global-nav/sections'
import { dark, opacity } from 'themes'
import { FadeIn } from 'components/shared/animation'
import trackingIds from 'tracking/tracking-ids'
import UserNavMenu from './UserNavMenu'
import MainNavMenu from './MainNavMenu'

const GlobalNavModal = ({ section, toggle, showOnMobile }) => {
  const width = useBodyWidth()
  const isMobile = checkIsMobile(width)
  if (isMobile && !showOnMobile) toggle()

  const ChosenMenu = Menus[section]

  return (
    <ThemeProvider theme={dark}>
      <Modal
        as="ol"
        toggle={toggle}
        justifyContent="center"
        alignItems="center"
        backgroundOpacity={opacity.desktopMenuBackground}
        display={{ s: showOnMobile ? 'flex' : 'none', m: 'flex' }}
        data-tracking-id={trackingIds.navModal}
      >
        <FadeIn>
          <HeaderPosition>
            <Layout>
              <Modal.Header toggle={toggle} />
            </Layout>
          </HeaderPosition>
          <ChosenMenu />
        </FadeIn>
      </Modal>
    </ThemeProvider>
  )
}

const HeaderPosition = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
`

const Menus = {
  [sections.main]: MainNavMenu,
  [sections.user]: UserNavMenu,
}

GlobalNavModal.propTypes = {
  section: PropTypes.oneOf(Object.values(sections)).isRequired,
  toggle: PropTypes.func.isRequired,
  showOnMobile: PropTypes.bool,
}

GlobalNavModal.defaultProps = {
  showOnMobile: false,
}

export { GlobalNavModal as default }
