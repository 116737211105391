import {
  Text,
  Box,
  Alignment,
  Indicator,
  variants,
} from '@resident-advisor/design-system'
import ResponsiveImage from 'components/generic/responsive-image'
import PropTypes from 'prop-types'
import Link from 'components/generic/link'
import { profileByUsernameRoute } from 'lib/routes'
import { useMenuContext } from 'context/MenuContext'
import testIds from 'enums/testIds'

const PERSON_COMPONENT_TYPE = {
  default: 'default',
  compact: 'compact',
}

const Person = ({
  imageUrl,
  name,
  color,
  fontWeight,
  textVariant,
  size,
  testId,
  hasNotifications,
  username,
  contributor,
  underline,
  type,
  ...props
}) => {
  const [{ variant }] = useMenuContext()

  return (
    <Alignment alignItems="center" data-test-id={testId} {...props}>
      <Box width={size} height={size} position="relative">
        <ResponsiveImage
          url={imageUrl}
          borderRadius="circular"
          alt={`${name} profile image`}
        />
        {hasNotifications && (
          <Box
            data-testid={testIds.notificationsIndicator}
            position="absolute"
            top={0}
            right={0}
          >
            <Indicator pulse variant={variant} />
          </Box>
        )}
      </Box>

      <Alignment alignItems="flex-end" ml={2}>
        {contributor && username ? (
          <Link
            variant={textVariant}
            color={color}
            fontWeight={fontWeight}
            underline={underline}
            {...profileByUsernameRoute(`/profile/${username}`)}
            display={{
              s: type === PERSON_COMPONENT_TYPE.compact ? 'none' : 'block',
              l: 'block',
            }}
          >
            {name}
          </Link>
        ) : (
          <Text
            variant={textVariant}
            color={color}
            fontWeight={fontWeight}
            display={{
              s: type === PERSON_COMPONENT_TYPE.compact ? 'none' : 'block',
              l: 'block',
            }}
          >
            {name}
          </Text>
        )}
      </Alignment>
    </Alignment>
  )
}

Person.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  textVariant: PropTypes.string,
  size: PropTypes.number,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  testId: PropTypes.string,
  hasNotifications: PropTypes.bool,
  username: PropTypes.string,
  contributor: PropTypes.bool,
  underline: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(PERSON_COMPONENT_TYPE)),
}

Person.defaultProps = {
  size: 20,
  textVariant: variants.text.label,
  type: PERSON_COMPONENT_TYPE.default,
}

export default Person
export { PERSON_COMPONENT_TYPE }
