import { Alignment, Box } from '@resident-advisor/design-system'
import GlobalNavList from './GlobalNavList'
import useGlobalNavItems from './useGlobalNavItems'

const MainNavMenu = () => {
  const navItems = useGlobalNavItems()

  return (
    <Alignment>
      {navItems.map((items, index) => (
        <Box key={`main-nav-menu-${index}`} width={{ m: 200, l: 300 }}>
          <GlobalNavList items={items} height="100%" />
        </Box>
      ))}
    </Alignment>
  )
}

export default MainNavMenu
