// https://usehooks.com/useWindowSize/
import { useEffect, useCallback, useState } from 'react'
import canUseDom from 'lib/canUseDom'

const useBodyWidth = () => {
  const getSize = useCallback(() => {
    if (!canUseDom()) {
      return false
    }

    const { width } = document.body.getBoundingClientRect()

    return width
  }, [])

  const [bodyWidth, setBodyWidth] = useState(getSize)

  useEffect(() => {
    if (!canUseDom()) {
      return false
    }

    function handleResize() {
      setBodyWidth(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [getSize])

  return bodyWidth
}

export default useBodyWidth
