import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

const Redirect = ({ to }) => {
  const { replace } = useRouter()

  useEffect(() => {
    replace(to)
  }, [replace, to])

  return null
}

Redirect.propTypes = {
  to: PropTypes.string.isRequired,
}

export default Redirect
