import styled from 'styled-components'
import { Text, Alignment, variants } from '@resident-advisor/design-system'
import PropTypes from 'prop-types'
import hoverColorStyles from './hoverColorStyles'

const GlobalNavItem = ({ children, color, Icon, hoverColor }) => (
  <HoverAlignment
    color={color}
    alignItems="center"
    justifyContent="center"
    ml={{ s: 2, m: 3 }}
    mr={{ s: 2, m: 3 }}
    hoverColor={hoverColor}
  >
    {Icon}
    <Text
      color="inherit"
      variant={variants.text.small}
      fontWeight="bold"
      textAlign="center"
      pl={Icon ? 1 : 0}
      css={{ whiteSpace: 'no-wrap' }}
    >
      {children}
    </Text>
  </HoverAlignment>
)

const HoverAlignment = styled(Alignment)`
  ${hoverColorStyles}
`

GlobalNavItem.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  Icon: PropTypes.node,
  hoverColor: PropTypes.string,
}

export default GlobalNavItem
