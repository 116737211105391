import PropTypes from 'prop-types'
import { useState } from 'react'
import styled, { ThemeProvider, css } from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import {
  Icon,
  Text,
  Alignment,
  Box,
  Layout,
  SearchIcon,
  variants,
} from '@resident-advisor/design-system'
import SearchModal from 'components/shared/search-modal'
import { useIntl } from 'react-intl'
import messages from 'messages/dict'
import trackingIds from 'tracking/tracking-ids'
import buttonTrackingIds from 'tracking/button-tracking-ids'
import { dark, light } from 'themes'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import { useMenuContext } from 'context/MenuContext'
import PLAYWRIGHT_TEST_IDS from 'enums/playwright-test-ids'
import MobileNavItems from './MobileNavItems'
import UserNavMenuItems from './user-nav-menu-items'

const MobileNavContent = () => {
  const [showSearch, setShowSearch] = useState(false)
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)
  const [{ globalNavHeight }] = useMenuContext()

  const toggleShowSearch = () => setShowSearch(!showSearch)

  return (
    <ThemeProvider theme={enableNewMainNav ? dark : light}>
      <StyledLayout
        enableNewMainNav={enableNewMainNav}
        data-tracking-id={trackingIds.mobileNavModal}
        data-pw-test-id={PLAYWRIGHT_TEST_IDS.mobileNavModal}
        height={enableNewMainNav ? '100%' : '100vh'}
        pb={enableNewMainNav ? 2 : 0}
      >
        <Alignment
          flex={1}
          pt={3}
          pb={enableNewMainNav ? globalNavHeight : 0}
          mb={enableNewMainNav ? 4 : 0}
          flexDirection="column"
          justifyContent="space-between"
        >
          <UserNavMenuItems />
          <Alignment
            flex={1}
            flexDirection="column"
            justifyContent={enableNewMainNav ? 'start' : 'center'}
            pb={enableNewMainNav ? 0 : 5}
          >
            {!enableNewMainNav && (
              <SearchButton toggleShowSearch={toggleShowSearch} />
            )}
            <MobileNavItems enableNewMainNav={enableNewMainNav} />
          </Alignment>
        </Alignment>
        <AnimatePresence>
          {showSearch && <SearchModal toggle={toggleShowSearch} />}
        </AnimatePresence>
      </StyledLayout>
    </ThemeProvider>
  )
}

const SearchButton = ({ toggleShowSearch }) => {
  const intl = useIntl()

  return (
    <Box pb={4} pr={3}>
      <Box pr={3}>
        <StyledSearchButton
          onClick={toggleShowSearch}
          data-button-tracking-id={buttonTrackingIds.openMobileNavSearchModal}
        >
          <Alignment alignItems="center" justifyContent="start">
            <Icon size={40} color="accent" Component={SearchIcon} mr={2} />
            <Text variant={variants.text.heading.xl} color="secondary">
              {intl.formatMessage(messages.search)}
            </Text>
          </Alignment>
        </StyledSearchButton>
      </Box>
    </Box>
  )
}

SearchButton.propTypes = {
  toggleShowSearch: PropTypes.func.isRequired,
}

const StyledSearchButton = styled.button`
  border: none;
  background: transparent;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.input.borderColor};
`

const StyledLayout = styled(Layout)`
  ${({ height, theme, enableNewMainNav }) => css`
    height: ${height};
    position: relative;
    background-color: ${enableNewMainNav
      ? 'black'
      : theme.mobileModal.backgroundColor};
  `}
`

export default MobileNavContent
