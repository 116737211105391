import { defineMessages } from 'react-intl'

const messages = defineMessages({
  '00': {
    id: 'Locations.00',
    defaultMessage: 'Streamland',
  },
  ad: {
    id: 'Locations.Ad',
    defaultMessage: 'Andorra',
  },
  ae: {
    id: 'Locations.Ae',
    defaultMessage: 'United Arab Emirates',
  },
  af: {
    id: 'Locations.Af',
    defaultMessage: 'Afghanistan',
  },
  ag: {
    id: 'Locations.Ag',
    defaultMessage: 'Antigua and Barbuda',
  },
  al: {
    id: 'Locations.Al',
    defaultMessage: 'Albania',
  },
  am: {
    id: 'Locations.Am',
    defaultMessage: 'Armenia',
  },
  ao: {
    id: 'Locations.Ao',
    defaultMessage: 'Angola',
  },
  ar: {
    id: 'Locations.Ar',
    defaultMessage: 'Argentina',
  },
  at: {
    id: 'Locations.At',
    defaultMessage: 'Austria',
  },
  au: {
    id: 'Locations.Au',
    defaultMessage: 'Australia',
  },
  az: {
    id: 'Locations.Az',
    defaultMessage: 'Azerbaijan',
  },
  ba: {
    id: 'Locations.Ba',
    defaultMessage: 'Bahrain',
  },
  bb: {
    id: 'Locations.Bb',
    defaultMessage: 'Barbados',
  },
  bd: {
    id: 'Locations.Bd',
    defaultMessage: 'Bangladesh',
  },
  be: {
    id: 'Locations.Be',
    defaultMessage: 'Belgium',
  },
  bf: {
    id: 'Locations.Bf',
    defaultMessage: 'Burkina Faso',
  },
  bg: {
    id: 'Locations.Bg',
    defaultMessage: 'Bulgaria',
  },
  bh: {
    id: 'Locations.Bh',
    defaultMessage: 'Belize',
  },
  bi: {
    id: 'Locations.Bi',
    defaultMessage: 'Burundi',
  },
  bih: {
    id: 'Locations.Bih',
    defaultMessage: 'Bosnia and Herzegovina',
  },
  bj: {
    id: 'Locations.Bj',
    defaultMessage: 'Benin',
  },
  bm: {
    id: 'Locations.Bm',
    defaultMessage: 'Bermuda',
  },
  bn: {
    id: 'Locations.Bn',
    defaultMessage: 'Brunei',
  },
  bo: {
    id: 'Locations.Bo',
    defaultMessage: 'Bolivia',
  },
  br: {
    id: 'Locations.Br',
    defaultMessage: 'Brazil',
  },
  bs: {
    id: 'Locations.Bs',
    defaultMessage: 'Bahamas',
  },
  bt: {
    id: 'Locations.Bt',
    defaultMessage: 'Bhutan',
  },
  bw: {
    id: 'Locations.Bw',
    defaultMessage: 'Botswana',
  },
  by: {
    id: 'Locations.By',
    defaultMessage: 'Belarus',
  },
  ca: {
    id: 'Locations.Ca',
    defaultMessage: 'Canada',
  },
  cd: {
    id: 'Locations.Cd',
    defaultMessage: 'Democratic Republic of the Congo',
  },
  cf: {
    id: 'Locations.Cf',
    defaultMessage: 'Central African Republic',
  },
  cg: {
    id: 'Locations.Cg',
    defaultMessage: 'Republic of the Congo',
  },
  ch: {
    id: 'Locations.Ch',
    defaultMessage: 'Switzerland',
  },
  ci: {
    id: 'Locations.Ci',
    defaultMessage: 'Ivory Coast',
  },
  cl: {
    id: 'Locations.Cl',
    defaultMessage: 'Chile',
  },
  cm: {
    id: 'Locations.Cm',
    defaultMessage: 'Cameroon',
  },
  cn: {
    id: 'Locations.Cn',
    defaultMessage: 'China',
  },
  co: {
    id: 'Locations.Co',
    defaultMessage: 'Colombia',
  },
  cr: {
    id: 'Locations.Cr',
    defaultMessage: 'Costa Rica',
  },
  cu: {
    id: 'Locations.Cu',
    defaultMessage: 'Cuba',
  },
  cv: {
    id: 'Locations.Cv',
    defaultMessage: 'Cape Verde',
  },
  cw: {
    id: 'Locations.Cw',
    defaultMessage: 'Curacao',
  },
  cy: {
    id: 'Locations.Cy',
    defaultMessage: 'Cyprus',
  },
  cz: {
    id: 'Locations.Cz',
    defaultMessage: 'Czech Republic',
  },
  de: {
    id: 'Locations.De',
    defaultMessage: 'Germany',
  },
  dj: {
    id: 'Locations.Dj',
    defaultMessage: 'Djibouti',
  },
  dk: {
    id: 'Locations.Dk',
    defaultMessage: 'Denmark',
  },
  dm: {
    id: 'Locations.dm',
    defaultMessage: 'Dominica',
  },
  do: {
    id: 'Locations.do',
    defaultMessage: 'Dominican Republic',
  },
  dz: {
    id: 'Locations.Dz',
    defaultMessage: 'Algeria',
  },
  ec: {
    id: 'Locations.Ec',
    defaultMessage: 'Ecuador',
  },
  ee: {
    id: 'Locations.Ee',
    defaultMessage: 'Estonia',
  },
  eg: {
    id: 'Locations.Eg',
    defaultMessage: 'Egypt',
  },
  er: {
    id: 'Locations.Er',
    defaultMessage: 'Eritrea',
  },
  es: {
    id: 'Locations.Es',
    defaultMessage: 'Spain',
  },
  et: {
    id: 'Locations.Et',
    defaultMessage: 'Ethiopia',
  },
  fi: {
    id: 'Locations.Fi',
    defaultMessage: 'Finland',
  },
  fj: {
    id: 'Locations.Fj',
    defaultMessage: 'Fiji',
  },
  fm: {
    id: 'Locations.Fm',
    defaultMessage: 'Federated States of Micronesia',
  },
  fr: {
    id: 'Locations.Fr',
    defaultMessage: 'France',
  },
  ga: {
    id: 'Locations.Ga',
    defaultMessage: 'Gabon',
  },
  gd: {
    id: 'Locations.Gd',
    defaultMessage: 'Grenada',
  },
  ge: {
    id: 'Locations.Ge',
    defaultMessage: 'Georgia',
  },
  gh: {
    id: 'Locations.Gh',
    defaultMessage: 'Ghana',
  },
  gi: {
    id: 'Locations.Gi',
    defaultMessage: 'Gibraltar',
  },
  gm: {
    id: 'Locations.Gm',
    defaultMessage: 'Gambia',
  },
  gn: {
    id: 'Locations.Gn',
    defaultMessage: 'Guinea',
  },
  gq: {
    id: 'Locations.Gq',
    defaultMessage: 'Equatorial Guinea',
  },
  gr: {
    id: 'Locations.Gr',
    defaultMessage: 'Greece',
  },
  gt: {
    id: 'Locations.Gt',
    defaultMessage: 'Guatemala',
  },
  gu: {
    id: 'Locations.Gu',
    defaultMessage: 'Guam',
  },
  gw: {
    id: 'Locations.Gw',
    defaultMessage: 'Guinea-Bissau',
  },
  gy: {
    id: 'Locations.Gy',
    defaultMessage: 'Guyana',
  },
  hn: {
    id: 'Locations.Hn',
    defaultMessage: 'Honduras',
  },
  hr: {
    id: 'Locations.Hr',
    defaultMessage: 'Croatia',
  },
  ht: {
    id: 'Locations.Ht',
    defaultMessage: 'Haiti',
  },
  hu: {
    id: 'Locations.Hu',
    defaultMessage: 'Hungary',
  },
  id: {
    id: 'Locations.Id',
    defaultMessage: 'Indonesia',
  },
  ie: {
    id: 'Locations.Ie',
    defaultMessage: 'Ireland',
  },
  il: {
    id: 'Locations.Il',
    defaultMessage: 'Israel',
  },
  in: {
    id: 'Locations.In',
    defaultMessage: 'India',
  },
  iq: {
    id: 'Locations.Iq',
    defaultMessage: 'Iraq',
  },
  ir: {
    id: 'Locations.Ir',
    defaultMessage: 'Iran',
  },
  is: {
    id: 'Locations.Is',
    defaultMessage: 'Iceland',
  },
  it: {
    id: 'Locations.It',
    defaultMessage: 'Italy',
  },
  jm: {
    id: 'Locations.Jm',
    defaultMessage: 'Jamaica',
  },
  jo: {
    id: 'Locations.Jo',
    defaultMessage: 'Jordan',
  },
  jp: {
    id: 'Locations.Jp',
    defaultMessage: 'Japan',
  },
  ke: {
    id: 'Locations.Ke',
    defaultMessage: 'Kenya',
  },
  kg: {
    id: 'Locations.Kg',
    defaultMessage: 'Kyrgyzstan',
  },
  kh: {
    id: 'Locations.Kh',
    defaultMessage: 'Cambodia',
  },
  ki: {
    id: 'Locations.Ki',
    defaultMessage: 'Kiribati',
  },
  km: {
    id: 'Locations.Km',
    defaultMessage: 'Comoros',
  },
  kn: {
    id: 'Locations.Kn',
    defaultMessage: 'Saint Kitts and Nevis',
  },
  kr: {
    id: 'Locations.Kr',
    defaultMessage: 'South Korea',
  },
  kw: {
    id: 'Locations.Kw',
    defaultMessage: 'Kuwait',
  },
  ky: {
    id: 'Locations.ky',
    defaultMessage: 'Cayman Islands',
  },
  kz: {
    id: 'Locations.Kz',
    defaultMessage: 'Kazakhstan',
  },
  la: {
    id: 'Locations.La',
    defaultMessage: 'Laos',
  },
  lb: {
    id: 'Locations.Lb',
    defaultMessage: 'Lebanon',
  },
  lc: {
    id: 'Locations.Lc',
    defaultMessage: 'Saint Lucia',
  },
  li: {
    id: 'Locations.Li',
    defaultMessage: 'Liechtenstein',
  },
  lk: {
    id: 'Locations.Lk',
    defaultMessage: 'Sri Lanka',
  },
  lr: {
    id: 'Locations.Lr',
    defaultMessage: 'Liberia',
  },
  ls: {
    id: 'Locations.Ls',
    defaultMessage: 'Lesotho',
  },
  lt: {
    id: 'Locations.Lt',
    defaultMessage: 'Lithuania',
  },
  lu: {
    id: 'Locations.Lu',
    defaultMessage: 'Luxembourg',
  },
  lv: {
    id: 'Locations.Lv',
    defaultMessage: 'Latvia',
  },
  ly: {
    id: 'Locations.Ly',
    defaultMessage: 'Libya',
  },
  ma: {
    id: 'Locations.Ma',
    defaultMessage: 'Morocco',
  },
  mc: {
    id: 'Locations.Mc',
    defaultMessage: 'Monaco',
  },
  md: {
    id: 'Locations.Md',
    defaultMessage: 'Moldova',
  },
  me: {
    id: 'Locations.Me',
    defaultMessage: 'Montenegro',
  },
  mg: {
    id: 'Locations.Mg',
    defaultMessage: 'Madagascar',
  },
  mh: {
    id: 'Locations.Mh',
    defaultMessage: 'Marshall Islands',
  },
  mk: {
    id: 'Locations.Mk',
    defaultMessage: 'North Macedonia',
  },
  ml: {
    id: 'Locations.Ml',
    defaultMessage: 'Mali',
  },
  mm: {
    id: 'Locations.Mm',
    defaultMessage: 'Myanmar',
  },
  mn: {
    id: 'Locations.Mn',
    defaultMessage: 'Mongolia',
  },
  mr: {
    id: 'Locations.Mr',
    defaultMessage: 'Mauritania',
  },
  mt: {
    id: 'Locations.Mt',
    defaultMessage: 'Malta',
  },
  mu: {
    id: 'Locations.Mu',
    defaultMessage: 'Mauritius',
  },
  mv: {
    id: 'Locations.Mv',
    defaultMessage: 'Maldives',
  },
  mw: {
    id: 'Locations.Mw',
    defaultMessage: 'Malawi',
  },
  mx: {
    id: 'Locations.Mx',
    defaultMessage: 'Mexico',
  },
  my: {
    id: 'Locations.My',
    defaultMessage: 'Malaysia',
  },
  mz: {
    id: 'Locations.Mz',
    defaultMessage: 'Mozambique',
  },
  na: {
    id: 'Locations.Na',
    defaultMessage: 'Namibia',
  },
  ne: {
    id: 'Locations.Ne',
    defaultMessage: 'Niger',
  },
  ng: {
    id: 'Locations.Ng',
    defaultMessage: 'Nigeria',
  },
  nl: {
    id: 'Locations.Nl',
    defaultMessage: 'Netherlands',
  },
  no: {
    id: 'Locations.No',
    defaultMessage: 'Norway',
  },
  np: {
    id: 'Locations.Np',
    defaultMessage: 'Nepal',
  },
  nr: {
    id: 'Locations.Nr',
    defaultMessage: 'Nauru',
  },
  nu: {
    id: 'Locations.Nu',
    defaultMessage: 'Nicaragua',
  },
  nz: {
    id: 'Locations.Nz',
    defaultMessage: 'New Zealand',
  },
  om: {
    id: 'Locations.Om',
    defaultMessage: 'Oman',
  },
  pa: {
    id: 'Locations.Pa',
    defaultMessage: 'Panama',
  },
  pe: {
    id: 'Locations.Pe',
    defaultMessage: 'Peru',
  },
  pg: {
    id: 'Locations.Pg',
    defaultMessage: 'Papua New Guinea',
  },
  ph: {
    id: 'Locations.Ph',
    defaultMessage: 'Philippines',
  },
  pk: {
    id: 'Locations.Pk',
    defaultMessage: 'Pakistan',
  },
  pl: {
    id: 'Locations.Pl',
    defaultMessage: 'Poland',
  },
  pr: {
    id: 'Locations.Pr',
    defaultMessage: 'Puerto Rico',
  },
  ps: {
    id: 'Locations.Ps',
    defaultMessage: 'Palestine',
  },
  pt: {
    id: 'Locations.Pt',
    defaultMessage: 'Portugal',
  },
  pw: {
    id: 'Locations.Pw',
    defaultMessage: 'Palau',
  },
  py: {
    id: 'Locations.Py',
    defaultMessage: 'Paraguay',
  },
  qa: {
    id: 'Locations.Qa',
    defaultMessage: 'Qatar',
  },
  rn: {
    id: 'Locations.Rn',
    defaultMessage: 'Saint Martin',
  },
  ro: {
    id: 'Locations.Ro',
    defaultMessage: 'Romania',
  },
  rs: {
    id: 'Locations.Rs',
    defaultMessage: 'Serbia',
  },
  ru: {
    id: 'Locations.Ru',
    defaultMessage: 'Russia',
  },
  rw: {
    id: 'Locations.Rw',
    defaultMessage: 'Rwanda',
  },
  sa: {
    id: 'Locations.Sa',
    defaultMessage: 'Saudi Arabia',
  },
  sb: {
    id: 'Locations.Sb',
    defaultMessage: 'Solomon Islands',
  },
  sc: {
    id: 'Locations.Sc',
    defaultMessage: 'Seychelles',
  },
  sd: {
    id: 'Locations.Sd',
    defaultMessage: 'Sudan',
  },
  se: {
    id: 'Locations.Se',
    defaultMessage: 'Sweden',
  },
  sg: {
    id: 'Locations.Sg',
    defaultMessage: 'Singapore',
  },
  si: {
    id: 'Locations.Si',
    defaultMessage: 'Slovenia',
  },
  sk: {
    id: 'Locations.Sk',
    defaultMessage: 'Slovakia',
  },
  sl: {
    id: 'Locations.Sl',
    defaultMessage: 'Sierra Leone',
  },
  sm: {
    id: 'Locations.Sm',
    defaultMessage: 'San Marino',
  },
  so: {
    id: 'Locations.So',
    defaultMessage: 'Somalia',
  },
  sr: {
    id: 'Locations.Sr',
    defaultMessage: 'Suriname',
  },
  ss: {
    id: 'Locations.Ss',
    defaultMessage: 'South Sudan',
  },
  st: {
    id: 'Locations.St',
    defaultMessage: 'Sao Tome and Principe',
  },
  sv: {
    id: 'Locations.Sv',
    defaultMessage: 'El Salvador',
  },
  sy: {
    id: 'Locations.Sy',
    defaultMessage: 'Syria',
  },
  sz: {
    id: 'Locations.Sz',
    defaultMessage: 'Eswatini',
  },
  td: {
    id: 'Locations.Ts',
    defaultMessage: 'Chad',
  },
  tg: {
    id: 'Locations.Tg',
    defaultMessage: 'Togo',
  },
  th: {
    id: 'Locations.Th',
    defaultMessage: 'Thailand',
  },
  tj: {
    id: 'Locations.Tj',
    defaultMessage: 'Tajikistan',
  },
  tk: {
    id: 'Locations.Tk',
    defaultMessage: 'Turkey',
  },
  tl: {
    id: 'Locations.Tl',
    defaultMessage: 'East Timor',
  },
  tm: {
    id: 'Locations.Tm',
    defaultMessage: 'Turkmenistan',
  },
  tn: {
    id: 'Locations.Tn',
    defaultMessage: 'Tunisia',
  },
  to: {
    id: 'Locations.To',
    defaultMessage: 'Tonga',
  },
  tt: {
    id: 'Locations.Tt',
    defaultMessage: 'Trinidad and Tobago',
  },
  tv: {
    id: 'Locations.Tv',
    defaultMessage: 'Tuvalu',
  },
  tw: {
    id: 'Locations.Tw',
    defaultMessage: 'Taiwan',
  },
  tz: {
    id: 'Locations.Tz',
    defaultMessage: 'Tanzania',
  },
  ua: {
    id: 'Locations.Ua',
    defaultMessage: 'Ukraine',
  },
  ug: {
    id: 'Locations.Ug',
    defaultMessage: 'Uganda',
  },
  uk: {
    id: 'Locations.Uk',
    defaultMessage: 'United Kingdom',
  },
  us: {
    id: 'Locations.Us',
    defaultMessage: 'United States of America',
  },
  uy: {
    id: 'Locations.Uy',
    defaultMessage: 'Uruguay',
  },
  uz: {
    id: 'Locations.Uz',
    defaultMessage: 'Uzbekistan',
  },
  ve: {
    id: 'Locations.Ve',
    defaultMessage: 'Venezuela',
  },
  vg: {
    id: 'Locations.Vg',
    defaultMessage: 'British Virgin Islands',
  },
  vn: {
    id: 'Locations.Vn',
    defaultMessage: 'Vietnam',
  },
  vu: {
    id: 'Locations.Vu',
    defaultMessage: 'Vanuatu',
  },
  ws: {
    id: 'Locations.Ws',
    defaultMessage: 'Samoa',
  },
  xk: {
    id: 'Locations.Xk',
    defaultMessage: 'Kosovo',
  },
  za: {
    id: 'Locations.Za',
    defaultMessage: 'South Africa',
  },
  zm: {
    id: 'Locations.Zm',
    defaultMessage: 'Zambia',
  },
  zw: {
    id: 'Locations.Zw',
    defaultMessage: 'Zimbabwe',
  },
  sn: {
    id: 'Locations.Sn',
    defaultMessage: 'Senegal',
  },
})

export default messages
