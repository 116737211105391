import { css } from 'styled-components'

const hoverColorStyles = css`
  &&& {
    &:hover {
      ${({ theme, hoverColor }) =>
        hoverColor
          ? css`
              color: ${hoverColor};
            `
          : css`
              color: ${theme.navigation.hoverColor};
            `}
    }
  }
`
export default hoverColorStyles
