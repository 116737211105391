import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import Tracking, { TRACKING_EVENT } from 'scripts/tracking'
import useIsMobile from 'hooks/useIsMobile'

const useNavLinkTracking = (): ((
  linkClicked: string,
  index?: number
) => void) => {
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)
  const isMobile = useIsMobile()

  // If index is included and is 0, it means a top level nav
  // link was clicked (eg Events, Magazine etc), and we don't
  // currently want to track these, just the sub links.
  return (linkClicked, index) => {
    if (index === undefined || index !== 0) {
      Tracking.trackMixpanel(TRACKING_EVENT.navMenuItemClicked, {
        'Nav Version': enableNewMainNav ? 'New' : 'Old',
        'Link Clicked': linkClicked,
        View: isMobile ? 'Mobile' : 'Desktop',
      })
    }
  }
}

export default useNavLinkTracking
