import PropTypes from 'prop-types'
import styled, { css, ThemeProvider } from 'styled-components'
import {
  deviceWidths as media,
  colors,
  Icon,
  Indicator,
  Box,
} from '@resident-advisor/design-system'
import buttonTrackingIds from 'tracking/button-tracking-ids'
import { useUserContext } from 'context/UserContext'

import { dark } from 'themes'
import PLAYWRIGHT_TEST_IDS from 'enums/playwright-test-ids'
import testIds from 'enums/testIds'

const CornerNavToggle = ({ onClick, Icon: IconProp, menuOpen }) => {
  const { user } = useUserContext()
  const userHasNotification =
    user?.newMessagesCount || user?.newEventRevisionsCount

  return (
    <ThemeProvider theme={dark}>
      <CornerNavStyled
        onClick={onClick}
        data-button-tracking-id={`${buttonTrackingIds.toggleMobileNavModal}-${
          menuOpen ? 'closed' : 'open'
        }`}
        data-pw-test-id={PLAYWRIGHT_TEST_IDS.cornerNav}
        data-testid={testIds.cornerNavToggle}
      >
        <Triangle />
        <StyledIcon size={40} Component={IconProp} color="white" />
        {userHasNotification && !menuOpen && (
          <Box position="absolute" bottom={38} right={10}>
            <Indicator variant="secondary" pulse />
          </Box>
        )}
      </CornerNavStyled>
    </ThemeProvider>
  )
}

CornerNavToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.elementType.isRequired,
  menuOpen: PropTypes.bool.isRequired,
}

const CornerNavStyled = styled.div`
  right: 0;
  bottom: 0;
  position: fixed;
  color: ${colors.coral};

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  ${({ theme }) => css`
    width: ${theme.ramBurgerHeight}px;
    height: ${theme.ramBurgerHeight}px;
    z-index: ${theme.zIndex.cornerNavToggle};
  `}

  ${media.m`
    display: none;
  `}
`

const Triangle = () => (
  <svg viewBox="0 0 1 1">
    <polygon fill="currentColor" points="0,1 1,1, 1,0" />
  </svg>
)

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 15px;
  bottom: 10px;
`

export default CornerNavToggle
