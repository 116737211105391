import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Slash, Alignment, variants } from '@resident-advisor/design-system'
import Link from 'components/generic/link'

const UserNavMenuItem = ({
  linkProps,
  onClick,
  title,
  index,
  strikeThrough,
  Component,
  textVariant,
}) => (
  <Alignment alignItems="center" height="100%">
    {index > 0 && (
      <Slash variant={variants.text.heading.s} color="tertiary" pl={2} pr={2} />
    )}
    <MenuLink
      color="primary"
      variant={textVariant ?? variants.text.bodyBold}
      href={linkProps?.href}
      onClick={onClick}
      strikeThrough={strikeThrough}
    >
      {title}
    </MenuLink>
    {Component && <Component />}
  </Alignment>
)

UserNavMenuItem.propTypes = {
  href: PropTypes.string,
  title: PropTypes.string.isRequired,
  index: PropTypes.number,
  strikeThrough: PropTypes.bool,
  onClick: PropTypes.func,
  linkProps: PropTypes.shape({
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  Component: PropTypes.func,
  textVariant: PropTypes.string,
}

UserNavMenuItem.defaultProps = {
  index: 0,
}

const MenuLink = styled(Link)`
  white-space: nowrap;
`

export default UserNavMenuItem
